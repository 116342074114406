/*#395CBE background color => Transaparency change only*/


/*https://codepen.io/jidelambo/pen/RaRygY*/

body {
    /*background-image: url(../img/header_bg.jpg?v3);
  z-index: 2;
  background-size: cover;
  background-attachment: fixed;*/
    /*margin-bottom: 80px !important;*/
    padding: 0px !important;
    min-height: auto !important;
}

html {
    position: relative;
    min-height: 100%;
}

.bg-full-image {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: relative;
}

.bg-full-image:before {
    background-image: url(../img/header_bg.jpg?v3) !important;
    background-size: cover !important;
    background-attachment: fixed;
    content: "";
    display: block;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 200vh !important;
}

.footer {
    position: relative;
    width: 100%;
}

.footer-container .footer {
    height: inherit !important;
    line-height: inherit !important;
    margin-bottom: 10px !important;
}

.base-footer .footer {
    position: relative !important;
    margin-top: 0 !important;
}

.post-box {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 5%);
    padding: 20px;
}

.emd-box1 {
    height: 90%!important;
    margin-bottom: 20px!important;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);
}

.emd-box1:hover {
    box-shadow: 0px 0px 16px rgb(0 0 0 / 40%);
}

.post-box .username {
    margin: 0px 30px;
}

.post-box .description {
    margin: 18px 0px;
}

.post-title {
    line-height: 24px;
}

.post-foot {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
}

.post-box:hover {
    transform: inherit !important;
}

.custom-wrapper .footer {
    position: relative;
}

.custom-wrapper .footer {
    position: relative;
}

.color-white {
    color: white !important;
}

.bg-overlay {
    /* background: rgba(57, 92, 190, 0.35); */
    background: rgba(63, 81, 181, 0.5) !important;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.all-wrapper.with-pattern {
    background-image: none !important;
    min-height: 65vh !important;
}


/* .main-content-container {
    padding-top: 60px;
} */

.custom-content-container {
    margin-top: 30px;
}

.auth-box-w.wider {
    max-width: 750px !important;
    /* max-width: 100% !important; */
    border-radius: 50px !important;
}

.auth-box-w.terms-wider {
    max-width: 90% !important;
}

.login-container {
    margin-top: 40px;
}

.auth-box-w.login-box {
    /* margin: 2em 0; */
    border-radius: 50px !important;
}

.auth-box-w .logo-w {
    padding: 20px 0 0 0 !important;
}

.auth-box-w {
    background-color: #395cbe !important;
    opacity: 0.95 !important;
}

.custom-form .form-control {
    padding: 18px !important;
    border-radius: 30px !important;
    border: none !important;
    color: grey !important;
    font-size: 16px !important;
}

.custom-form ::placeholder {
    color: grey !important;
    opacity: 1;
}

.custom-submit-button {
    width: 100% !important;
    border-radius: 50px !important;
    padding: 20px !important;
    background: linear-gradient(134deg, #56b8e4 0%, #6b8ccf 100%) !important;
    border: 1px solid white !important;
}

.auth-box-w .buttons-w {
    border-top: none !important;
}

.info-bottom-link {
    width: 100% !important;
    text-align: end !important;
    color: white !important;
    margin-bottom: 15px !important;
    float: right !important;
    padding-right: 15px !important;
    font-size: 18px !important;
}

.logout-link {
    color: white !important;
    float: right !important;
    padding: 5px 20px !important;
    font-size: 18px !important;
    border: 2px solid white;
    border-radius: 50px;
}

.terms-block {
    font-size: 18px !important;
    color: white !important;
}

.auth-box-w form {
    padding: 20px 40px 40px 40px !important;
}

.type-selector {
    border: 1px solid white !important;
    border-radius: 50px !important;
    padding: 15px !important;
    color: white !important;
}

.copyright-text {
    font-size: 14px !important;
}

.text-large {
    font-size: 16px;
}

.signuppage-info-bottom-link {
    color: white !important;
    font-size: 18px !important;
}

.type-selector label {
    padding-right: 15px !important;
    padding-left: 0.5rem !important;
    font-size: 16px;
}

.type-selector span {
    padding-left: 5px !important;
}

.gender-container label {
    padding-right: 10px !important;
    font-size: 16px;
}

.gender-container span {
    padding-left: 5px !important;
}


/* Styles for wrapping the search box */

.main {
    width: 50%;
    margin: 50px auto;
}

.custom-form {
    /* min-width: 375px; */
    /* display: inline-block */
}

.custom-form .input-group-text {
    position: absolute !important;
    z-index: 999 !important;
    top: 15% !important;
    left: 0% !important;
    padding: 14px !important;
    border-radius: 50px !important;
    margin-left: 5px !important;
    background: #3a5ebe !important;
    color: white !important;
}

.custom-form .input-group input {
    /* min-width: 350px; */
    text-indent: 2.75rem !important;
}

span.error {
    color: #ccd9e8 !important;
    font-size: 15px !important;
}

.login-container .auth-box-w .buttons-w {
    padding-top: 2rem !important;
}

.login-container {
    margin-top: 40px;
}

.custom-form .form-control {
    width: 100% !important;
}

.disabled {
    opacity: 0.9 !important;
    cursor: not-allowed !important;
}

.custom-top-bar {
    background-color: #395cbe !important;
}

.custom-bg-overlay {
    z-index: 0 !important;
}

.top-bar:not(.admin-top-bar) .top-menu-controls {
    padding: 25px 0 !important;
}

.customLogo {
    margin-left: 0px !important;
}

.custom-username {
    color: white !important;
    font-size: 16px;
}

.custom-wrapper {
    height: 100% !important;
    min-height: 100vh !important;
}

.custom-background-color {
    background-color: #5e9ed8 !important;
    height: auto !important;
    min-height: 100vh !important;
}

.user-dashboard-header-bg-color {
    background-color: #5e9ed8 !important;
    height: auto !important;
    min-height: auto !important;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.5) !important;
}

.custom-element-box {
    background-color: #395cbe !important;
    border-radius: 0 !important;
    color: white !important;
}

.custom-element-box .element-box {
    background-color: #395cbe !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
}

.menu_active {
    background-color: rgba(0, 0, 0, 0.08);
}

.kyc-container .element-wrapper {
    padding-bottom: 0.2rem !important;
}

.custom-element-box .form-header {
    color: white !important;
}

.kyc-container .form-control:not(.no-border-radius) {
    background-color: transparent !important;
    border-radius: 50px !important;
    color: white !important;
}

.kyc-container ::placeholder {
    color: white;
    opacity: 0.5;
}

.permian-button {
    padding: 15px 60px;
    border-radius: 50px;
    background-color: #395cbe !important;
}

.permian-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
}


/*.kyc-contact-block {
    min-height: 410px !important;
}
*/

.kyc-container select {
    color: white !important;
}

.kyc-container .dropzone {
    background-color: #395cbe !important;
    border: 2px dashed white !important;
}

.resource-continaer {
    background-color: #395cbe !important;
}

.resource-continaer.dropzoneIdContainer {
    padding: 20px !important;
}

.payment-proof-container {
    border: 2px dotted #395cbe !important;
    background-color: transparent !important;
}

.payment-proof-container.dropzoneIdContainer {
    padding: 2px !important;
}

.payment-proof-container label,
.payment-proof-container div {
    color: #395cbe !important;
}

.payment-proof-container .filepond--root {
    margin-bottom: 0px !important;
}

.country-select2 .cntry__control {
    background-color: transparent !important;
    border-radius: 50px !important;
    color: white;
}

.country-select2 ::placeholder,
.cntry__single-value,
.cntry__placeholder,
.cntry__indicators svg {
    color: white !important;
}

.country-select2--profile ::placeholder,
.cntry__single-value,
.cntry__placeholder,
.cntry__indicators svg {
    color: black !important;
}

.cntry__menu {
    background-color: #5e9ed8 !important;
    color: white !important;
}

.country-select2--profile .cntry__menu {
    background-color: white !important;
    color: black !important;
}

.cntry__option--is-focused {
    background-color: #395cbe !important;
}

.country-select2--profile .cntry__option--is-focused {
    background-color: #495057 !important;
    color: white;
}

.custom-content-container {
    overflow: hidden !important;
}

.dob-container .react-datepicker-wrapper,
.dob-container .react-datepicker__input-container {
    display: inline !important;
}

.min-width-50 {
    min-width: 45% !important;
}


/*.progress-steps-container .rc-steps-item-process .rc-steps-item-title,
.progress-steps-container .rc-steps-item-wait .rc-steps-item-title,
.progress-steps-container .rc-steps-item-finish .rc-steps-item-title {
    color: white !important;
    font-weight: 400;
    font-size: 16px;
}

.progress-steps-container .rc-steps-item-process .rc-steps-item-icon {
    background-color: white !important;
}

.progress-steps-container .rc-steps-item-wait .rc-steps-item-icon,
.progress-steps-container .rc-steps-item-finish .rc-steps-item-icon {
    background-color: transparent !important;
    border-color: white !important;
}

.progress-steps-container .rc-steps-item-process .rc-steps-item-icon>.rc-steps-icon {
    color: #395CBE !important;
}

.progress-steps-container .rc-steps-item-wait .rc-steps-item-icon>.rc-steps-icon {
    color: white !important;
}*/

.kyc-agree-container {
    margin-top: 25px !important;
    padding-left: 0px !important;
}

.kyc-agree-container label {
    font-size: 16px !important;
}

.progress-steps-container .rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: white;
}


/*.kycdoc-selfie-block {
    min-height: 350px;
}*/

.dropzoneIdContainer {
    padding: 10px 0 !important;
}


/*
.documentType{
  background: #5E9ED8 !important;
}
.documentType option{
  color:#395CBE !important;
}
*/

.rdtPicker {
    background-color: #5e9ed8 !important;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #5e9ed8 !important;
}

.campaignTable .table-striped {
    white-space: nowrap;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #5e9ed8 !important;
}

.rdtPicker thead tr:first-child th:hover {
    background: #5e9ed8 !important;
}

td.rdtMonth:hover,
td.rdtYear:hover {
    background: #5e9ed8 !important;
}

.cntry__input {
    color: white !important;
}


/*.kyc-document-container .custom-element-box {
    min-height: 460px !important;
}*/

.kyc-status-container {
    color: white !important;
}

.progress-icon-container {
    padding: 12px !important;
    background-color: #395cbe !important;
    border-radius: 50%;
}

.kycstatus-head {
    font-weight: 200 !important;
    font-size: 55px !important;
}

body.menu-position-side .content-w {
    border-radius: 0 !important;
}

.content-w {
    overflow: auto;
}

.required-sign {
    color: red !important;
}

.admin-container,
.admin-container .menu-w,
.user-dashboard-container,
.user-dashboard-container .menu-w {
    height: 100% !important;
    /* min-height: 100vh !important; */
    min-width: 16% !important;
}

.progress-icon-container.bg-red {
    background-color: red !important;
}

.kyc-status-button {
    background-color: #395cbe !important;
    border-radius: 50px !important;
    padding: 15px 20px !important;
}

.text-bold {
    font-weight: bolder !important;
}

.font-700 {
    font-weight: 700;
}

.widget-title {
    margin: 6px 0px;
}

.dashboard-card-badge {
    background: #ebebeb;
    border-radius: 8px;
    margin: 6px 0px;
    padding: 6px 12px;
    display: inline-block;
}

.buy-xpr-container {
    font-size: 16px !important;
}

.dark-blue-theme-color {
    border-radius: 8px;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
}

.dark-blue-font-color {
    color: #395cbe !important;
}

.buy-xpr-header-block {
    font-size: 18px !important;
}

.buy-xpr-input {
    width: 20% !important;
    padding-left: 20px !important;
    height: 30px !important;
    border-radius: 50px 0px 0px 50px;
    opacity: 1 !important;
    border: white !important;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    padding-right: 10px;
}

.buy-xpr-currencey-select {
    width: 8% !important;
    height: 30px !important;
    border-radius: 0px 50px 50px 0px;
    background-color: transparent;
    border-color: white !important;
    padding: 0 2px !important;
}

.buy-xpr-currencey-select option {
    background-color: #59adde !important;
}

.buy-xpr-currencey-select,
.buy-xpr-currencey-select option {
    color: white !important;
}

.tokens-xpr-input {
    width: 20% !important;
    padding-left: 20px !important;
    height: 30px !important;
    border-radius: 50px 0px 0px 50px;
    opacity: 1 !important;
    border: white !important;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    padding-right: 10px;
}

.token-symbol-input {
    width: 7% !important;
    background-color: #59adde !important;
    border: white !important;
    opacity: 1 !important;
    border-radius: 0px 50px 50px 0px;
    height: 30px !important;
    color: white !important;
}

.highligher {
    font-size: 16px;
    font-weight: 600;
}

.receive-text {
    font-size: 16px;
}

.flex-header {
    display: flex !important;
    justify-content: space-between;
    flex-direction: row;
}

.eth-contribution-input {
    width: 55% !important;
    border-radius: 50px !important;
    height: 30px !important;
    padding-left: 10px !important;
    border: white !important;
    opacity: 1;
    background-image: linear-gradient( 134deg, #59adde 0%, #57ccec 100%) !important;
    padding-left: 10px !important;
}

.buy-xpr-container h3,
.buy-xpr-container h6 {
    font-weight: 300 !important;
}

.buy-xpr-buttons {
    border-radius: 50px !important;
    margin-left: 20px !important;
    background-color: transparent;
    padding: 5px 15px !important;
    font-size: 16px;
    border: 1px solid white !important;
    font-weight: 200 !important;
}

.qrcode-image-container img {
    margin-top: 15% !important;
}

.buy-online-payment-buttons {
    width: 15%;
    background-color: transparent;
    border-radius: 50px !important;
    border: 1px solid white !important;
}

.admin-error-msg,
.validation-red-error {
    color: red !important;
}

.instructon-modal-container {
    padding: 0 !important;
    border-radius: 8px !important;
}

.change-password-modal {
    width: 45%;
}

.instructon-modal-container .onboarding-modal .onboarding-content .onboarding-text {
    color: black !important;
}

.required-field {
    color: red;
}

.my-account-container .my-account-header-block h5 {
    font-weight: 400 !important;
}

.account-button {
    padding: 5px 10px !important;
    border-radius: 50px;
    background-color: #395cbe !important;
    border: 1px solid white !important;
}

.font-400 {
    font-weight: 400;
}

.text-small {
    font-size: 12px !important;
}

.text-default {
    font-size: 14px !important;
}

.text-large {
    font-size: 16px !important;
}

.text-xl {
    font-size: 20px;
}

.text-margin {
    margin: 6px 0px;
}

.card-inner-padding {
    padding: 24px;
}

.card-vertical-padding {
    padding: 24px 0;
}

.text-default-color {
    color: #333333;
}

.account-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
}

.my-account-button {
    margin: 10px 0 !important;
    padding: 10px 40px !important;
}

.my-account-container .kyc-success-icon-container {
    padding: 12px !important;
    background-color: white !important;
    color: #395cbe !important;
    border-radius: 50%;
}

.my-account-container .verified-text {
    font-size: 1.3rem !important;
}

.transaction-modal-container {
    width: 35%;
}

.my-account-container h5 span {
    font-size: 1.2rem;
}

.default-border-btn {
    background-color: #ffffff;
    color: #395cbe;
    border: 1px solid #395cbe;
}

.default-btn {
    background-color: #395cbe;
    color: #ffffff;
    border: 1px solid #395cbe;
}

.buy-currency-box-display {
    padding: 2.5rem !important;
    border-radius: 50px !important;
    background-color: grey !important;
}

.buy-copy-button {
    padding: 15px;
    border-radius: 50px;
    background-color: #395cbe !important;
    border-color: white !important;
    margin-left: 15px !important;
}

.modal-contribution-address {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.my-account-header-block {
    min-height: 240px !important;
}

.two-fa-code-input {
    width: 240px !important;
    display: inline-block !important;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin: 0 10px !important;
}

.two-factor-heading-text {
    font-size: 16px !important;
}

.receiving-eth-address {
    border-radius: 30px !important;
    padding: 8px !important;
    border: none !important;
    color: grey !important;
    font-size: 14px !important;
}

.kyc-file-upload-container {
    width: 100% !important;
    display: table !important;
    cursor: pointer !important;
    height: 8rem !important;
    border: 1px white dashed;
}

.kyc-multifile-upload-container {
    width: 100% !important;
    display: table !important;
    cursor: pointer !important;
    height: auto !important;
    border: 1px white dashed;
    min-height: 8rem !important;
}

.upload-text-display {
    display: table-cell !important;
    vertical-align: middle !important;
}

.width-auto {
    width: auto !important;
    display: inline !important;
}

.display-inline {
    display: inline !important;
}

.onboarding-modal .onboarding-slide .onboarding-content:not(.tokenpayment-content-modal) {
    padding: 24px !important;
}

.progressbar {
    counter-reset: step !important;
}

.progressbar li {
    list-style: none !important;
    display: inline-block !important;
    width: 30.33% !important;
    position: relative !important;
    text-align: center !important;
    /* cursor: pointer !important; */
}

.progressbar li:before {
    content: counter(step) !important;
    counter-increment: step !important;
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
    border: 1px solid #ddd !important;
    border-radius: 100% !important;
    display: block !important;
    text-align: center !important;
    margin: 0 auto 10px auto !important;
    background-color: #fff !important;
}

.react-bootstrap-table-pagination-list {
    display: flex;
    justify-content: flex-end;
}

.progressbar li:after {
    content: "" !important;
    position: absolute !important;
    width: 100% !important;
    height: 1px !important;
    background-color: white !important;
    top: 15px !important;
    left: -50% !important;
    z-index: 0 !important;
}

.progressbar li:first-child:after {
    content: none !important;
}

.progressbar li.active {
    color: white !important;
}

.progressbar li.active:before {
    border-color: white !important;
    color: #395cbe !important;
    font-weight: bolder;
}

.progressbar li.active+li:after {
    background-color: white !important;
    z-index: 1 !important;
}

.filter-element-wrapper {
    padding-bottom: 0rem !important;
}

.filter-element-wrapper .form-control {
    width: 100% !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-pointer:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.dashboard-header {
    font-size: 15px !important;
}

.color-red {
    color: red !important;
}

.dashboard-statistics-container:not(:last-child),
.user-statistics-container {
    border-right: 1px solid white !important;
}

.barrel-display {
    display: inline !important;
    padding: 3px 10px !important;
    border-radius: 50px !important;
    background: #395cbe !important;
    color: white !important;
}

.btn-center-group-form {
    display: flex;
    justify-content: center;
}

.bg-white {
    background: white !important;
}

.dashboard-my-investment-list td {
    padding: 0.25rem !important;
}

.el-tablo .value {
    font-size: 1rem !important;
}

.dashboard-my-investment-list.table thead th {
    border-bottom: none !important;
}

.text-underline {
    text-decoration: underline !important;
}

.custom-tooltip {
    display: inline;
    position: relative;
}

.custom-tooltip:hover:after {
    background: #000;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    bottom: 26px;
    color: #fff;
    content: attr(tooltip-title);
    left: 20%;
    padding: 0.25rem 0.5rem;
    position: absolute;
    z-index: 98;
}

.menu-w .logged-user-menu.color-style-light .logged-user-info-w .logged-user-role,
.logged-user-name {
    color: #333333;
}

.barrel-display {
    display: inline !important;
    padding: 1px 10px !important;
}

.logged-user-w .logged-user-role {
    font-size: 0.6rem;
}

.custom-tooltip:hover:before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;
    content: "";
    left: 50%;
    position: absolute;
    z-index: 99;
}

.custom-tooltip.tooltip-right:hover:after {
    right: 20% !important;
    left: inherit !important;
}

.custom-tooltip.tooltip-right:hover:before {
    right: 50% !important;
    left: inherit !important;
}

.display-inherit {
    display: inherit !important;
}

.white-background {
    background-color: white !important;
}

.xpr-chart-header {
    color: #395cbe !important;
    font-size: 16px !important;
}

.qrcode-image-container img {
    padding-bottom: 20px !important;
}

.document-type-header {
    color: #395cbe !important;
}

.filepond--root {
    min-height: 8rem !important;
    /*max-height: 15rem !important;*/
    border: 2px dotted white !important;
}

.filepond--panel-root {
    background-color: transparent !important;
}

.filepond--drop-label {
    color: white !important;
    top: 20% !important;
}

.filepond--file-action-button.filepond--action-process-item {
    display: none !important;
}

.split-radio-container label {
    min-width: 35% !important;
}

.understanding2-split-radio-container label {
    min-width: 33% !important;
}

.wealth-split-radio-container label {
    min-width: 25% !important;
    margin-top: 5px !important;
}

.understanding-split-radio-container label {
    min-width: 20% !important;
    margin-top: 5px !important;
}

.radio-half-width-container label {
    min-width: 50% !important;
    margin-top: 5px !important;
}

.financial-products-invested label {
    min-width: 25% !important;
    margin-top: 5px !important;
}

.financial-information-textarea {
    background-color: transparent !important;
    color: white !important;
    resize: none !important;
}

.signature-pad-container {
    border: 2px dotted white !important;
}

.clear-sign-action {
    font-size: 0.8rem !important;
}

.terms-container {
    padding: 40px !important;
    color: white !important;
    text-align: justify;
}

.cntry__menu-list {
    max-height: 10rem !important;
}

.identification-data-container,
.identification-data-container h3 {
    font-size: 16px !important;
}

.identification-data-container .row {
    margin-bottom: 10px !important;
}

.signature-container-img {
    max-width: 100% !important;
    width: 100% !important;
}

.user-dashboard-content {
    background-color: #fafafa !important;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
}

.user-dashboard-menu {
    background-color: white !important;
    position: fixed !important;
    left: 0 !important;
    bottom: 0 !important;
    z-index: 99 !important;
    top: 91px !important;
}

.user-dashboard-menu li span {
    color: #395cbe !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    opacity: 1 !important;
}

.header-fixed-menu {
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    z-index: 100 !important;
}

.center-content {
    padding-top: 90px !important;
}

.user-dashboard-content {
    /* margin-left: 16% !important; */
    position: relative !important;
}

.alert-dark-blue {
    background-color: #395cbe !important;
    border-color: #395cbe !important;
    color: white !important;
    font-size: 18px !important;
}

.worth-highlighter {
    font-size: 13px !important;
    color: #d8cdcd !important;
    /* font-style: italic !important; */
}

.user-dashboard-content {
    min-height: 86vh !important;
}

.o-auto {
    overflow: auto !important;
}

.content-box {
    padding-bottom: 0 !important;
    width: 100%;
}

.progressbar li:after {
    width: calc(100% - 30px) !important;
    margin-left: 15px;
    margin-right: 15px;
}

.buyNrtNotes {
    padding: 40px !important;
}

.buyNrtNotes ol {
    padding-inline-start: 15px;
}


/* ol.incrementNo {
    counter-reset: item
}

.incrementNo li {
    display: block
}

.incrementNo li:before {
    content: counters(item, ".") " ";
    counter-increment: item
} */


/* ol.incrementNo {
    counter-reset: item
}

.incrementNo li {
    display: block
}

.incrementNo li:before {
    content: counters(item, ".") " ";
    counter-increment: item
} */


/* ol.incrementNo {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol.incrementNo>li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol.incrementNo>li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

.incrementNo li ol>li {
    margin: 0;
}

.incrementNo li ol>li:before {
    content: counters(item, ".") " ";
}

 */

.instructon-modal-container OL {
    counter-reset: item;
}

.instructon-modal-container LI {
    display: block;
}

.clearNumber {
    counter-reset: list !important;
}

.clearNumber .incrementNo li {
    list-style: none !important;
    position: relative !important;
}

.clearNumber li::before {
    counter-increment: list !important;
    content: "(" counter(list, lower-alpha) ") " !important;
}

.clearAlpha {
    counter-reset: list !important;
}

.clearAlpha li {
    list-style: none !important;
    position: relative !important;
}

.clearAlpha li::before {
    counter-increment: list !important;
    content: "(" counter(list, lower-roman) ") " !important;
}

.clearNumbers {
    counter-reset: number !important;
}

.clearNumbers li::before {
    counter-increment: number !important;
    content: " " counter(number) ". " !important;
}

.underline {
    text-decoration: underline !important;
}

.instructionCheck {
    margin-left: 1rem !important;
    display: inline !important;
    margin-bottom: 1rem !important;
}

.declarationCheck {
    float: left !important;
}

.declarationLabel {
    display: block !important;
    margin-left: 40px !important;
}


/* .permian-alert {
    color: #ff0101 !important;
    background-color: #f9dfda !important;
    border-color: #f9dfda !important;
    font-size: 16px !important;
}

.permian-alert a {
    color: white !important;
    text-decoration: underline !important;
} */

.my-account-error-msg {
    font-size: 16px !important;
    /* box-shadow: 2px 3px red; */
    border: 1px solid red;
}

.my-account-error-msg a {
    color: white !important;
    text-decoration: underline !important;
}

.myaccount-error-icon {
    color: red !important;
    font-size: 20px !important;
}

.assumption-block {
    color: black !important;
}

.w-350 {
    width: 350px;
}

.exchange-currnecy {
    color: white;
    background-color: transparent;
    border-color: white;
    border-radius: 50px;
    padding-left: 10px;
    margin-left: 10px;
}

.exchange-btn {
    background: transparent !important;
    border-color: white !important;
    border-radius: 50px !important;
    padding: 4px 45px !important;
    color: white !important;
    border: 1px solid white !important;
}

.cursor-pointer {
    cursor: pointer;
}

.exchange-input {
    margin-left: 10px !important;
    padding-left: 15px !important;
    height: 30px !important;
    border-radius: 50px !important;
    opacity: 1 !important;
    border: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: right !important;
    padding-right: 10px !important;
    width: 70% !important;
    text-align: left !important;
}

.exchange-xpr-label {
    font-size: 13px !important;
}

.exchange-input:focus,
.exchange-input:focus,
.exchange-currnecy:focus,
.exchange-input2:focus,
.exchange-input2:focus,
.exchange-btn2:focus {
    outline: none !important;
}

.xpr-receive-text {
    font-size: 11px !important;
}

.exchange-dashboard-content {
    /* background-color: #59ADDE !important; */
    padding-top: 91px;
    min-height: 100vh !important;
    background: rgb(47, 61, 69);
}

.mandate-book-container {
    padding: 1rem !important;
}

.sell-text {
    /* color: #e65252 !important */
    /* color: rgb(234, 0, 112) !important; */
    color: rgb(249, 103, 45) !important;
}

.buy-text {
    color: green !important;
}

.mandate-header {
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.exchange-header-block {
    font-size: 14px !important;
    /* background-color: #293145 !important; */
    background: rgb(21, 35, 44) !important;
}

.pending-text {
    color: #bdbd24 !important;
}

.accepted-text,
.executed-text {
    color: green !important;
}

.rejected-text {
    color: red;
}

.border-right-seperator {
    border-right: 1px solid white;
}

.note-text {
    overflow-wrap: break-word;
}

.exchange-input2 {
    margin-left: 5px !important;
    padding-left: 10px !important;
    height: 22px !important;
    border-radius: 10px;
    opacity: 1 !important;
    border: white !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: right !important;
    padding-right: 10px !important;
    width: 100% !important;
    text-align: left !important;
}

.exchange-label {
    font-size: 11px !important;
}

.exchange-btn2 {
    background: transparent !important;
    border-color: white !important;
    border-radius: 50px !important;
    padding: 3px 18px !important;
    color: white !important;
    border: 1px solid white !important;
    font-size: 11px !important;
}

.exchange-currnecy option {
    /* background: #59ADDE !important; */
    background: rgb(47, 61, 69) !important;
    color: #fff;
}

.content-panel.color-scheme-dark {
    background: rgb(21, 35, 44) !important;
}

.exchange-top-bar {
    background-color: rgb(25, 33, 38) !important;
}

.signinpt {
    padding-top: 3.5rem !important;
}

.exchange-base-footer {
    margin-top: 25px;
    padding: 0px 0px;
    font-size: 7px;
    background: #192126;
    position: absolute !important;
    width: 100% !important;
    bottom: 0 !important;
}

.exchange-footer {
    height: 30px;
    line-height: 30px;
    padding: inherit;
    font-size: 14px !important;
}

.permian-exchange-container .table.table-compact td,
.permian-exchange-container .table th {
    padding-left: 0px !important;
}

.unit-price-seperator {
    color: grey !important;
}

#recent-order-container {
    min-height: 142px;
}

.wallet-address {
    overflow-wrap: break-word;
}

.subscribe-modal-content {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
}

.account-radio-container label {
    min-width: 12% !important;
}

.notification-text {
    font-size: 16px !important;
}

.exchange-usd-submit-btn {
    padding: 7px 20px !important;
}

.exchange-wallet-copy-address {
    padding: 5px 15px !important;
    margin-left: 10px !important;
    border: none !important;
}

.exchange-wallet-copy-address:focus {
    box-shadow: none !important;
    border: none !important;
}

.exchange-currency-modal-container .modal-dialog {
    max-width: 800px !important;
    padding: 0 !important;
}

.dealer-profile-modal-container .modal-dialog {
    max-width: 800px !important;
    padding: 0 !important;
}

.mandate-book-container input[type="number"]::-webkit-outer-spin-button,
.mandate-book-container input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
}

.os-dropdown-trigger.os-dropdown-position-left:hover .os-dropdown {
    -webkit-transform: translate3d(0%, 100%, 0) !important;
    transform: translate3d(0%, 100%, 0) !important;
}

.os-dropdown-trigger:hover .os-dropdown {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
}

body.page-loaded .css3-spinner {
    display: none !important;
}

.exchange-content-container {
    padding: 0 !important;
}

.sell-text,
.buy-text {
    font-size: 14px !important;
    font-weight: 150 !important;
}

.top-bar .top-icon i {
    color: white !important;
}

.menu-vertical-align-set {
    vertical-align: inherit !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.userList-btn-group {
    margin-top: 10px !important;
}

.metamaskbutton {
    position: relative;
    height: 60px;
    min-width: 200px;
    background: none;
    border: none;
    color: white;
    border-radius: 60px;
    padding: 0 25px 0 40px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient( to right, #f7861c 0%, rgba(0, 0, 0, 0) 100%);
    background-color: #c86dd7;
    cursor: pointer;
    line-height: 20px;
    font-size: 18px;
    transition: 400ms all;
    outline: 0 !important;
}

.metamaskbutton:hover {
    background-color: #8f68e4;
}

.metamask-payment-container .logo {
    top: 8px;
    left: -10px;
    width: 42px;
    height: 42px;
    position: absolute;
    /* z-index: 1; */
}

.metamask-payment-container .text {
    font-weight: 600;
}

.metamask-payment-container .withMetamask {
    margin-left: 6px;
    opacity: 0.7;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
}

.metamask-payment-container .amounts {
    font-weight: 600;
}

.metamask-payment-container .amountBig {
    font-size: 20px;
}

.metamask-payment-container .amountSmall {
    font-size: 16px;
}

.metamask-payment-container .conversion {
    opacity: 0.7;
    font-weight: 400;
}

.metamask-payment-container .approx {
    position: relative;
    top: -1px;
    margin: 0 5px;
    font-size: 14px;
}

.metamask-payment-container .status {
    font-size: 15px;
}

.metamask-payment-container img.symbolEthLogo {
    width: 15px;
}

.metamask-payment-container .symbolEthLogo {
    position: relative;
    top: -1px;
    margin-left: 2px;
    display: none;
}

.metamask-payment-container .symbolEth {
    margin-left: 5px;
}

.react-confirm-alert-button-group {
    display: flex !important;
    justify-content: flex-end !important;
}

.react-confirm-alert-body {
    border-radius: 8px !important;
}

.widget-title {
    color: #333333;
}

.react-confirm-alert-body>h1 {
    font-size: 20px !important;
    line-height: 20px;
    margin: 12px 0px;
}

.react-confirm-alert-button-group button:first-child {
    background-color: #ffffff;
    color: #395cbe;
    border-radius: 8px;
    border: 1px solid #395cbe;
}

.react-confirm-alert-button-group button:last-child {
    background-color: #395cbe;
    color: #ffffff;
    border-radius: 8px;
    border: 1px solid #395cbe;
}

.token-payment-modal .cntry__single-value,
.token-payment-modal .cntry__placeholder,
.token-payment-modal .cntry__indicators svg {
    color: black !important;
}

.token-payment-modal-container {
    min-width: 500px !important;
}

.display-block {
    display: block !important;
}

.ticker-widget-container {
    overflow: hidden !important;
}

.token-address-text {
    font-size: 16px !important;
    font-weight: bolder !important;
}

.cryptounitpricetext {
    color: white !important;
    font-size: 14px !important;
}

.tokenpayment-content-modal {
    padding: 40px 20px !important;
}

.normalfontweight {
    font-weight: normal !important;
}

.subscribe-pay-request-submit,
.custom-button-padding {
    padding: 6px 30px !important;
}

.my-balance-element-wrapper {
    padding-bottom: 1.5rem !important;
}

.currency-type-select2 .cntry__control,
.currency-type-select2 .cntry__single-value {
    color: #495057 !important;
}

#chaparral_ticker {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem !important;
    border-radius: 5px !important;
}

.c-ticker {
    background-color: rgba(0, 0, 0, 0.7);
}

#chaparral_ticker .stock_ticker_data {
    right: 0xp !important;
    width: 180px;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #fff;
    font-weight: normal;
    font-size: 14px !important;
    text-align: left;
    box-shadow: 0 1px 2px #fff, /*bottom external highlight*/
    0 -1px 1px #666, /*top external shadow*/
    inset 0 -1px 1px rgba(0, 0, 0, 0.5), /*bottom internal shadow*/
    inset 0 1px 1px rgba(255, 255, 255, 0.8);
    /*top internal highlight*/
    border-radius: 5px !important;
}

.c-ticker .chs_exchange {
    margin-bottom: -0.4em;
    padding-left: 15px;
    color: #999;
}

.c-ticker .chs_symbol {
    padding-left: 15px;
    font-size: 18px;
}

.c-ticker .chs_symbol,
.c-ticker .chs_pricing {
    display: inline-block;
}

#chaparral_ticker .stock_ticker_data {
    right: 0xp !important;
    width: 180px;
    padding-top: 10px;
    color: #fff;
    font-weight: normal;
    font-size: 14px !important;
    text-align: left;
}

.c-ticker .chs_pricing {
    font-size: 18px;
}

.c-ticker .chs_symbol,
.c-ticker .chs_pricing {
    display: inline-block;
}

#chaparral_ticker .stock_ticker_data .chs_pricing .ticker__green {
    color: #9f0 !important;
}

.mb-40 {
    margin-bottom: 40px;
}


/* .centerofscreen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */


/* .auth-box-w.centerofscreen {
  min-width: 40% !important;
}
 */

.auth-box-w.signupwidth {
    margin: 2em 0;
    min-width: 50% !important;
}

.footer-container {
    clear: both;
    /* position: relative !important; */
}

.form-check {
    padding-left: 0.25rem !important;
}

.register-container {
    margin-top: 40px;
}

.text-underline {
    text-decoration: underline !important;
}

.basepage-footer-container {
    position: fixed !important;
    bottom: 5px !important;
    left: 0px !important;
    right: 0px !important;
    margin-bottom: 0px !important;
}

.width-35 {
    width: 35% !important;
}

.signuppt {
    padding-top: 2.5rem !important;
}

.button-center-user {
    width: 100%;
    display: inline-block;
    text-align: center;
    justify-content: center;
    display: flex;
}

.signin-info-bottom-link {
    text-align: end !important;
    color: white !important;
    font-size: 18px !important;
}

.signup-button {
    margin-bottom: 0rem !important;
}

.content-box {
    min-height: 78vh !important;
}

.transactioncontainer .tabs {
    margin: 0 auto;
    width: 100%;
    margin-top: 2vh;
}

.transactioncontainer img {
    width: 300px;
}

.transactioncontainer .tab-buttons {
    margin-bottom: 25px;
    border-bottom: 2px solid #59adde;
}

.transactioncontainer button:not(.dropdown-toggle) {
    margin-right: 15px;
    font-size: 20px;
    background: transparent;
    border: none;
    outline: none;
    padding: 0 20px 10px 20px;
    cursor: pointer;
    color: #395cbe !important;
    transition: all ease-in-out 0.2s;
    border-bottom: 2px solid transparent;
}

.transactioncontainer button.active {
    border-bottom: 2px solid #59adde;
    color: black;
}

.sendtokennote {
    display: inline-block !important;
    color: red !important;
    font-size: 10px !important;
    font-weight: bold !important;
}

.campaignmaps {
    height: 100vh !important;
}

.top3 {
    top: 3px !important;
}

.listview-campaigns {
    position: absolute;
    top: 10px;
    height: auto;
    z-index: 1;
    right: 70px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    color: white;
}


/* .dealer-users-filter-element-wrapper .form-control,
.kyc-filter-element-wrapper .form-control {
  width: 15rem !important;
} */

.verifier-modal {
    min-width: 450px !important;
}

.token-settings-container .element-wrapper {
    padding-bottom: 1rem !important;
}

.dealer-site-settings-container .form-buttons-w {
    margin-top: 0 !important;
    padding-top: 0 !important;
    border-top: none !important;
}

.border-top-none {
    border-top: none !important;
}

.menu-w .logged-user-menu.color-style-bright {
    background-color: #395cbe;
}

.menu-w .logged-user-menu .logged-user-avatar-info {
    margin-bottom: 10px;
}

.menu-w .logged-user-menu ul {
    padding: 0px 15px !important;
    padding-bottom: 10px !important;
}

.padding-initial {
    padding: initial !important;
}

.submenuleftpadding {
    padding-left: 16px !important;
}

.menu-padding-set {
    padding-left: 1.5rem !important;
}

.menu-w.menu-layout-compact .logged-user-w.avatar-inline .logged-user-i .logged-user-info-w {
    padding-left: 15px !important;
}

.display-block {
    display: block !important;
}

.filter-element-wrapper .input-full-width {
    width: 100% !important;
}

.update-payment-method-input {
    width: 100% !important;
    padding-left: 15px !important;
    height: 30px !important;
    border-radius: 50px 0px 0px 50px;
    opacity: 1 !important;
    border: white !important;
    font-size: 16px;
    font-weight: 500;
    padding-right: 10px;
}

.update-payment-method-input:focus {
    outline: none !important;
}

.width-full-issue {
    width: 100%;
}

.average-listing-input {
    border-radius: 50px !important;
}

.file-upload-border:nth-child(odd) {
    border-right: 1px solid white;
    /* padding-right: 20px !important; */
}

.view-key {
    position: absolute;
    top: 48%;
    right: 20px;
}

.centerofthescreen {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 1 !important;
}

.menu-position-side.menu-w {
    /* border-radius: 6px 0px 0px 6px; */
    width: 16%;
}

.form-check {
    padding-left: 10px !important;
}

.min-height-90vh {
    min-height: 95vh !important;
}

.min-width-40 {
    min-width: 40% !important;
}

.top-bar.custom-top-bar {
    min-height: 90px !important;
}

.react-confirm-alert-body h1 {
    font-size: 1.5rem !important;
}

.react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactCrop__image {
    height: 500px !important;
}

@media (max-width: 1150px) {
    .content-panel {
        visibility: visible !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 0 !important;
    }
}

@media (min-width: 1025px) {
    .user-dashboard-content {
        margin-left: 16% !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .menu-mobile .mm-logo-buttons-w {
        display: flex !important;
    }
}

@media (max-width: 1024px) {
    .user-dashboard-content {
        margin-left: 0% !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1140px) {
    .my-account-card-title {
        font-size: 16px !important;
    }
}

@media (max-width: 992px) {
    .form-inline .form-group {
        margin-bottom: 1rem !important;
    }
}

.menu-mobile.color-scheme-dark {
    background-color: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    color: #333333;
}

.menu-container {
    background-color: #ffffff;
}

.account-type {
    float: right;
}

@media screen and (min-width: 170px) and (max-width: 575px) {
    .confirmPasswordContainer {
        margin-top: 1.2em;
    }
    .emailContainer {
        margin-top: 1.2em;
    }
    .account-type {
        float: left !important;
    }
    .file-upload-border:nth-child(odd) {
        border: none;
        /* padding-right: 20px !important; */
    }
    .small-text {
        font-size: 13px;
    }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
    .buy-xpr-header-block {
        /* margin-top: 3em !important; */
        font-size: 14px !important;
    }
    .accountInfo {
        margin-bottom: 20px;
    }
    .file-upload-border:nth-child(odd) {
        border: none;
        /* padding-right: 20px !important; */
    }
}

@media screen and (min-width: 481px) and (max-width: 576px) {
    .flex-header-md {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
}

@media screen and (min-width: 481px) and (max-width: 500px) {
    .two-factor-heading-text,
    .twofa-submit-button {
        margin-top: 15px !important;
    }
}

@media screen and (min-width: 576px) and (max-width: 658px) {
    .two-factor-heading-text,
    .twofa-submit-button {
        margin-top: 15px !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1191px) {
    .two-factor-heading-text,
    .twofa-submit-button {
        margin-top: 15px !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
    .accountInfo {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .top-bar {
        padding: 0px;
        flex-direction: unset;
    }
    .kyc-document-img {
        height: 150px;
    }
    .mobile-center-img {
        display: flex;
        justify-content: center;
    }
    .m-btn-center {
        display: flex;
        justify-content: center;
    }
    .mgtp-sm-4 {
        margin-top: 1.5rem;
    }
    .mgtp-sm-3 {
        margin-top: 1rem;
    }
    .change-password-modal {
        width: 70%;
    }
}

@media screen and (min-width: 480px) and (max-width: 991px) {
    .dashboard-statistics-container:nth-child(even),
    .user-statistics-container {
        border-right: none !important;
    }
}

@media screen and (min-width: 100px) and (max-width: 480px) {
    .mgtp-xs-3 {
        margin-top: 1rem;
    }
    .change-password-modal {
        width: 100%;
    }
    .flex-header {
        flex-direction: column;
    }
    .form-check {
        padding-left: 0px !important;
    }
    .pl-none {
        padding-left: 0px !important;
    }
    .rc-anchor-normal .rc-anchor-checkbox-label {
        width: 90px !important;
    }
    .accountInfo {
        margin-bottom: 20px;
    }
    .rc-anchor-normal-footer {
        display: inline-block;
        height: 74px;
        vertical-align: top;
        width: 70px !important;
    }
    .rc-anchor-normal {
        height: 74px;
        width: 241px !important;
        /* width: 300px; */
    }
    .rc-anchor-normal .rc-anchor-content {
        height: 74px;
        width: 161px !important;
    }
    .rc-anchor-normal .rc-anchor-pt {
        margin: 5px 0px 0px 24px;
        width: 276px !important;
    }
    .auth-box-w.wider,
    .auth-box-w.login-box {
        margin-top: 2em;
        border-radius: 0px !important;
    }
    /* .custom-form .input-group input {
    min-width: 310px;
    text-indent: 2.75rem !important;
  } */
    .auth-box-w form,
    .auth-box-w.login-box form {
        padding: 20px 20px 40px 20px !important;
        width: 351px;
    }
    .auth-box-w {
        background-color: transparent !important;
    }
    .auth-box-w.signupwidth {
        margin-top: 2em;
        min-width: 50% !important;
    }
    iframe {
        width: 300px;
    }
    .auth-box-w .logo-w {
        padding: 20px 0 0 0 !important;
        width: 351px;
    }
    .terms-block,
    .info-bottom-link {
        font-size: 14px !important;
    }
    .copyright-text {
        margin-top: 3em !important;
        font-size: 14px !important;
        padding-bottom: 10px !important;
    }
    .main-content-container {
        margin-top: 0px !important;
    }
    .custom-form .input-group-text {
        top: 10% !important;
        padding: 10px !important;
    }
    .custom-form .form-control {
        padding: 12px !important;
    }
    .wrapper {
        margin-top: 10% !important;
    }
    .top-bar:not(.admin-top-bar) .top-menu-controls {
        padding: 10px 0 !important;
    }
    .split-radio-container label,
    .wealth-split-radio-container label,
    .understanding-split-radio-container label {
        min-width: 100% !important;
    }
    .main-content-container {
        padding-top: 0 !important;
    }
    .menu-container {
        background-color: #ffffff;
    }
    .custom-top-bar {
        display: none !important;
    }
    .customLogo {
        margin-left: 0 !important;
    }
    .menu-mobile .mm-logo-buttons-w .mm-logo img {
        width: auto !important;
    }
    .dashboard-statistics-container:not(:last-child),
    .user-statistics-container {
        border-right: none !important;
    }
    .buy-xpr-header-block {
        font-size: 14px !important;
    }
    .dashboard-statistics-container {
        margin: 10px 0 !important;
    }
    .my-account-details:not(:last-child) {
        margin-bottom: 20px !important;
    }
    .dealer-profile-modal-container .onboarding-modal .onboarding-slide .onboarding-content {
        padding: 15px !important;
    }
    .width-full-issue {
        width: 100%;
    }
    .my-account-container h5 span {
        font-weight: 0.8rem !important;
    }
    .my-account-container h5 span.pull-right {
        margin-top: 0.4rem !important;
    }
    .two-factor-block {
        /* text-align: center !important; */
    }
    .two-factor-heading-text,
    .twofa-submit-button {
        margin-top: 15px !important;
    }
    .two-factor-desc-text {
        display: contents;
    }
    .two-factor-heading-header {
        margin-top: 40px !important;
    }
    .buy-xpr-input,
    .tokens-xpr-input {
        width: 15% !important;
        padding-left: 5px !important;
        font-size: 14px !important;
        padding-right: 5px !important;
    }
    .buy-xpr-block {
        padding: 0.8rem !important;
    }
    .buy-xpr-container {
        font-size: 12px !important;
    }
    .converter-sign {
        padding-left: 0.5rem !important;
    }
    .xpr-tokens-label {
        padding-left: 0.5rem !important;
    }
    .buy-xpr-label {
        padding-right: 0.5rem !important;
        margin-top: 0.3rem !important;
    }
    .token-symbol-input {
        width: 10% !important;
    }
    .eth-contribution-input {
        width: 100% !important;
        margin-top: 10px !important;
    }
    .buy-xpr-buttons {
        padding: 5px 15px !important;
        margin-top: 10px !important;
        margin-left: 0 !important;
    }
    .buy-online-payment-buttons {
        width: auto !important;
        margin-top: 10px !important;
    }
    .user-dashboard-content {
        min-height: 84.5vh !important;
    }
    .wallet-header {
        font-size: 14px !important;
    }
    .buy-xpr-container h6 {
        font-weight: 200;
    }
    .identification-header {
        font-size: 18px !important;
    }
    .kyc-container.mt-4 {
        margin-top: 15px !important;
    }
    .identification-block .permian-button {
        padding: 15px 45px !important;
    }
    .country-top-margin {
        margin-top: 15px !important;
    }
    .our-service-container .iradio_square-blue {
        margin-left: 0px !important;
    }
    .exchange-top-bar {
        padding: 0px !important;
        flex-direction: inherit !important;
    }
    .exchange-top-bar img {
        height: 55px !important;
    }
    .exchange-dashboard-content {
        padding-top: 70px !important;
    }
    .exchange-content-container {
        padding: 0% !important;
    }
    .sell-text,
    .buy-text {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    .buy-xpr-container h6.sell-text,
    .buy-xpr-container h6.buy-text {
        font-weight: 600 !important;
    }
    .exchange-currnecy {
        float: right !important;
    }
    .xpr-unit-display-container,
    .wallet-balance-display-container {
        padding-top: 15px;
    }
    .xpr-unit-display-text {
        text-align: left !important;
    }
    .market-other-details-container .pl-2 {
        padding-left: 0px !important;
    }
    .wallet-balance-display-text {
        float: inherit !important;
    }
    .wallet-balance-display-text .exchange-btn2 {
        float: right !important;
    }
    .permian-exchange-content-panel {
        height: auto !important;
        min-height: auto !important;
    }
    .border-right-seperator {
        border-right: none !important;
    }
    .exchange-input2 {
        width: 80% !important;
    }
    .create-sell-mandate-container {
        margin-top: 25px !important;
        border-top: 1px solid white !important;
        padding-top: 25px !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .center-content {
        padding-top: 0px !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
    .footer,
    .base-footer .footer {
        line-height: inherit !important;
        margin-top: 30px !important;
        width: auto !important;
        margin: auto;
    }
    .two-factor-block .notification-text {
        text-align: left !important;
    }
    .account-radio-container label {
        min-width: 25% !important;
    }
    .mm-buttons {
        pointer-events: auto !important;
        cursor: pointer !important;
    }
    .auth-box-w {
        box-shadow: none !important;
    }
    .signuppt {
        padding-top: 1.5rem !important;
    }
    .signup-button {
        padding: 10px !important;
    }
    .auth-box-w.signupwidth {
        margin-top: 0px !important;
    }
    .login-container,
    .register-container {
        margin-top: 0px !important;
    }
}

@media screen and (min-width: 180px) and (max-width: 961px) {
    .signuppage-info-bottom-link {
        width: 100%;
        text-align: center;
    }
    .signin-info-bottom-link {
        text-align: center !important;
    }
    .width-100 {
        width: 100% !important;
    }
    .btn-center-group {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .exchange-content-container {
        padding: 0% !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .exchange-input2 {
        width: 100% !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
    .mm-logo-buttons-w {
        display: none !important;
    }
}

@media screen and (max-device-width: 717px) {
    .filter-element-wrapper .form-control {
        width: 100% !important;
    }
    .userList-btn-group {
        margin-top: 10px !important;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.submit-button .btn-sm {
    width: 80px;
    height: 35px;
}

@media screen and (min-device-width: 1305px) and (max-device-width: 1480px) {
    .userList-btn-group {
        margin-top: 10px !important;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    .exchange-content-container {
        padding: 0% !important;
    }
    .exchange-input2 {
        width: 100% !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
    .mm-logo-buttons-w {
        display: none !important;
    }
}

@media only screen and (min-device-width: 576px) and (max-device-width: 992px) {
    .userList-btn-group {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media only screen and (min-device-width: 500px) and (max-device-width: 620px) {
    .verifier-modal {
        min-width: 400px !important;
    }
    .change-password-modal {
        width: 80%;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 500px) {
    .verifier-modal {
        min-width: 340px !important;
    }
}

@media only screen and (min-device-width: 100px) and (max-device-width: 400px) {
    .verifier-modal {
        min-width: 300px !important;
    }
    .react-confirm-alert-body {
        width: 300px !important;
    }
    .onboarding-modal {
        width: 100%;
    }
}

@media only screen and (min-device-width: 576px) and (max-device-width: 767px) {
    .mt-xs-3,
    .my-xs-3 {
        margin-top: 1rem !important;
    }
}

@supports (-moz-appearance: none) {
    .select-icon SELECT {
        -moz-appearance: none !important;
        background: transparent url(../img/down-arrow.svg?v3) right center no-repeat !important;
    }
}

@supports (-webkit-touch-callout: none) {
    .select-icon SELECT {
        -moz-appearance: none !important;
        background: transparent url(../img/down-arrow.svg?v3) right center no-repeat !important;
    }
}

.Whitelist-icon {
    margin-left: -3px;
    transform: scale(0.6);
    width: 37px;
    height: 31px;
}

.Whitelist-icon-mobile {
    width: 45px;
    background: transparent url(../img/Vectorsec.png) right center no-repeat !important;
    height: 31px;
}

@media only screen and (max-device-width: 768px) {
    .custom-top-bar {
        display: none !important;
    }
    .center-content {
        padding-top: 0px !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .Whitelist-icon-mobile {
        margin-left: 10px;
    }
    .change-password-modal {
        width: 60%;
    }
}

@media only screen and (min-device-width: 768px) and (orientation: portrait) {
    .content-box {
        min-height: 86vh !important;
    }
    .user-dashboard-content {
        min-height: 94vh !important;
    }
}

@media only screen and (min-device-width: 2000px) {
    .content-box {
        min-height: 90vh !important;
    }
    .user-dashboard-content {
        min-height: 96vh !important;
    }
}